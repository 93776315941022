<template>
    <div class="container">
        <div class="content">
            <div class="title">404 Not Found.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "404"
};
</script>

<style scoped>
.container {
    margin: 300px 0 0 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #B0BEC5;
    font-weight: 100;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.content {
    text-align: center;
    display: inline-block;
}

.title {
    font-size: 72px;
    margin-bottom: 40px;
}
</style>
